import { FlattenSimpleInterpolation } from "styled-components";

export enum NotificationType {
  RaffleEnd = "RaffleEnd",
  RaffleStart = "RaffleStart",
  RaffleRouletteStart = "RaffleRouletteStart",
  NewCalendarDay = "NewCalendarDay",
  NewSlot = "NewSlot",
  NewInhouseGame = "NewInhouseGame",
  StreamLive = "StreamLive",
  Custom = "Custom",
  NewStreamer = "NewStreamer",
}
export interface NotificationInfo {
  onActionClickLink?: string;
  actionButtonText?: string;
  angleLabel?: string;
  platform?: StreamPlatforms;
  startedAt?: string;
  streamer?: string;
  imageCode?: string;
  provider?: string;
  houseGameName?: string;
}

export interface NotificationUIModel {
  title: string | null;
  description: string | null;
  type: NotificationType;
  info: NotificationInfo;
}

export interface NotificationCreationModel extends NotificationUIModel {
  finishAt: string | null;
}

export interface NotificationUIModelExtended extends NotificationUIModel {
  id: number;
}

export interface NotificationTemplateUIModel extends NotificationUIModel {}

export interface NotificationCardProps extends NotificationUIModel {
  contentRenderer?: React.ReactElement;
  onActionButtonClick?: () => void;
  containerStyles?: FlattenSimpleInterpolation;
}

export enum StreamPlatforms {
  Twitch = "twitch",
  Kick = "kick",
}

export enum StreamPlatformsUrl {
  Twitch = "https://www.twitch.tv/",
  Kick = "https://kick.com/",
}

export interface NotificationData<T> {
  data: T;
  notificationData: NotificationCardProps;
}

export interface NotificationToastProps<T> {
  props: T;
  notificationData: NotificationCardProps;
}
