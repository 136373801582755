import React from "react";
import Image from "next/image";
import styled from "styled-components";
import { TheIcon } from "components";
// import { useAppContext } from "contexts";
import { Flex } from "components/common";
import {
  HyusLinks,
  WatchGamesTVLinks,
  // WATCH_GAMES_USERNAME,
  // HYUS_USERNAME,
  // WREWARDS_USERNAME,
  WRewardsLinks,
  // PKLE_USERNAME,
  PkleLinks,
  SamJuniorTVLinks,
} from "helpers/constants";
// import { ITheIconLink } from "interfaces";
import { Roll } from "react-awesome-reveal";

export interface IFeaturedCreatorsComponent {
  id?: string;
  nextScrollHref?: string;
}

const featureCreatorsItems = [
  {
    faceImage: "/images/FeaturedCreatorsComponent/watch-new.png",
    name: "watchgamestv",
    social: WatchGamesTVLinks,
  },
  {
    faceImage: "/images/FeaturedCreatorsComponent/hyus-new.png",
    name: "hyus",
    social: HyusLinks,
  },
  {
    faceImage: "/images/FeaturedCreatorsComponent/pkle-new.png",
    name: "pkle",
    social: PkleLinks,
  },
  {
    faceImage: "/images/FeaturedCreatorsComponent/wrewards-new.png",
    name: "wrewards",
    social: WRewardsLinks,
  },
  {
    faceImage: "/images/FeaturedCreatorsComponent/samjunior-new.png",
    name: "samjuniortv",
    social: SamJuniorTVLinks,
  },
];

const gradients = {
  watchgamestv: "linear-gradient(180deg, #00E5FF 0%, #00B2FF 15%, #001130 100%)",
  wrewards: "linear-gradient(180deg, #C8FF00 0%, #FFB200 15%, #300800 100%)",
  hyus: "linear-gradient(180deg, #FF6229 0%, #FF0048 45%, #300000 100%)",
  pkle: "linear-gradient(180deg, #FFF600 0%, #6FB50E 15%, #0A1F03 100%)",
};
// background: linear-gradient(180deg, #00E5FF 0%, #00B2FF 15%, #001130 100%), #181E30;
// background: linear-gradient(180deg, #FF6229 0%, #FF0048 45%, #300000 100%), #181E30;
// background: linear-gradient(180deg, #FFF600 0%, #6FB50E 15%, #0A1F03 100%), #2A521D;
// background: linear-gradient(180deg, #00E5FF 0%, #00B2FF 15%, #001130 100%), #181E30;

const FeaturedCreatorsComponent: React.FC<IFeaturedCreatorsComponent> = ({ id }): JSX.Element => {
  // const { liveStreams } = useAppContext();
  // const watchgamestvLiveStream = liveStreams?.find(
  //   (el: Stream) => el.isLive === true && el.streamer === WATCH_GAMES_USERNAME
  // );

  // const hyusLiveStream = liveStreams?.find(
  //   (el: Stream) => el.isLive === true && el.streamer === HYUS_USERNAME
  // );

  // const wrewardsLiveStream = liveStreams?.find(
  //   (el: Stream) => el.isLive === true && el.streamer === WREWARDS_USERNAME
  // );

  // const pkleLiveStream = liveStreams?.find(
  //   (el: Stream) => el.isLive === true && el.streamer === PKLE_USERNAME
  // );

  return (
    <Box id={id} className="container">
      <div>
        <Flex column align="center">
          <TextTitle>
            <Image
              src="/images/FeaturedCreatorsComponent/title-image.svg"
              alt="?"
              width={72}
              height={72}
              style={{ gridArea: "icon" }}
            />
            <span style={{ gridArea: "title" }}>Featured</span>
            <span style={{ gridArea: "subtitle" }} data-blue>
              creators
            </span>
          </TextTitle>

          <Roll triggerOnce style={{ width: "100%" }}>
            <CreatorsGrid>
              {featureCreatorsItems.map((creator) => {
                return (
                  <CreatorCard creatorName={creator.name} key={creator.name}>
                    <div className="header">
                      <div className="face">
                        <Image src={creator.faceImage} fill alt="" />
                      </div>

                      <span className="name">{creator.name}</span>
                    </div>
                    <div className="footer">
                      <p className="footer-text">Check Content</p>
                      <div className="links">
                        {Object.entries(creator.social).map(([type, url]) => {
                          return (
                            <a target="_blank" href={url} className={`link ${type}`} key={url}>
                              {/* @ts-ignore */}
                              <TheIcon icon={`${type}:social`} />
                            </a>
                          );
                        })}
                      </div>
                    </div>
                  </CreatorCard>
                );
              })}
            </CreatorsGrid>
          </Roll>
        </Flex>
      </div>
    </Box>
  );
};

export default FeaturedCreatorsComponent;

const Box = styled.div`
  /* background-color: #0b0e16; */
  margin: 4rem auto;
`;

const CreatorCard = styled.div<{ creatorName: string }>`
  background: ${({ creatorName = "watchgamestv" }) =>
    // @ts-ignore
    gradients?.[creatorName] ?? gradients.watchgamestv};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px;
  /* aspect-ratio: 1/1; */
  /* min-width: 200px; */
  /* flex:0 1 200px; */
  /* border: 1px solid rgba(171, 186, 219, 0.05); */

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 72px 12px 12px;
    position: relative;
  }

  .face {
    height: 120px;
    width: 120px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
  }

  .name {
    font-family: var(--font-family-exo);
    color: var(--daylight-daylight-main, #d5e0f5);
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 900;
    font-style: italic;
  }

  .footer {
    flex: 1;
    padding: 1rem;
    width: 100%;
    background: #141722;
    border-radius: 0 0 11px 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    /* grid-template-columns: repeat(4, 1fr); */
    /* place-content: center; */
  }

  .footer-text {
    font-family: var(--font-family-golos);
    margin: 0;
    font-weight: 500;
    font-size: 1rem;
  }

  .link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 40px;
    height: 40px;
    overflow: hidden;
    background-color: #181e30;
    border: 1px solid rgba(210, 234, 255, 0);
    border-radius: 12px;

    transition: background-color ease-in-out 0.2s, transform ease-in 0.1s;

    &:hover {
      background-color: #3b8be9;
      transform: scale(1.1);
      &.discord {
        background-color: #5562ea;
      }

      &.youtube {
        background-color: #f35;
      }

      &.kick {
        background-color: #53fc18;
      }

      &.instagram {
        background-color: #cb04dc;
      }

      &.twitch {
        background-color: #9046ff;
      }
    }
  }

  .links {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
  }
`;

const CreatorsGrid = styled.div`
  width: 100%;
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
  gap: 3.5rem 1rem;
  /* place-items: center;
  place-content: center; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (min-width: 1921px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 350px));

    & ${CreatorCard} {
      aspect-ratio: 1/0.8;
    }
  }

  @media (max-width: 1920px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 220px));
  }

  @media (max-width: 939px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  /* @media (max-width: 767px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 670px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  } */
`;

const Text = styled.div`
  color: rgba(210, 234, 255, 0.75);
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
`;

const TextTitle = styled(Text)`
  display: grid;
  color: var(--daylight-color);
  font-family: var(--font-family-exo);
  font-size: 24px;
  font-style: italic;
  font-weight: 900;
  line-height: 120%; /* 28.8px */
  text-transform: uppercase;
  gap: 12px 24px;
  margin-bottom: 8rem;
  align-items: center;
  text-align: left;
  grid-template-areas:
    "icon title"
    "icon subtitle";
  grid-template-columns: auto 1fr;

  & [data-blue] {
    color: var(--pure-sky);
    font-size: 48px;
    @media (max-width: 500px) {
      font-size: 26px;
    }
  }

  @media (max-width: 500px) {
    font-size: 20px;
    gap: 0 12px;
    margin-bottom: 32px;
  }
`;
